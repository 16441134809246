/* eslint-disable max-len */
import React from "react"
import PropTypes from 'prop-types'

//  thanks to https://openmoji.org/library/#search=hour&emoji=23F3

const Loading = ({className}) => {
  return (
    <div className={`w-16 h-16 ${className}`}>
      <svg id="emoji" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
        <g id="color">
          <path fill="#9B9B9A" stroke="none" d="M40.2139,31.1055c0.0039-0.002,0.0068-0.002,0.0107-0.002c0.0049-0.0019,0.0088-0.0039,0.0137-0.0058	C45.8926,29.6133,48,23.3076,48,19.6816V18H24v1.6816c0,3.626,2.1074,9.9317,7.7617,11.4161	c0.0049,0.0019,0.0088,0.0039,0.0137,0.0058c0.0039,0,0.0068,0,0.0107,0.002c1.792,0.4863,3.0996,1.5361,3.7491,2.8945h0.9296	C37.1143,32.6416,38.4219,31.5918,40.2139,31.1055z" />
          <path fill="#9B9B9A" stroke="none" d="M47,55c-6.0742,0-11-4.9258-11-11c0,6.0742-4.9258,11-11,11h-1v4h24v-4H47z" />
          <path fill="#2563EB" stroke="none" d="M55,11c0,1.1001-0.9004,2-2,2H19c-1.0996,0-2-0.8999-2-2v-1c0-1.1001,0.9004-2,2-2h34c1.0996,0,2,0.8999,2,2	V11z" />
          <path fill="#2563EB" stroke="none" d="M55,62c0,1.0996-0.9004,2-2,2H19c-1.0996,0-2-0.9004-2-2v-1c0-1.0996,0.9004-2,2-2h34c1.0996,0,2,0.9004,2,2	V62z" />
          <line x1="36" x2="36" y1="39" y2="44" fill="#FFFFFF" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
        </g>
        <g id="line">
          <path fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" d="M31,34c-7.2725-1.9092-10-9.5454-10-14.3184C21,14.9092,21,13,21,13" />
          <path fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" d="M21,59c0,0,0-1.9092,0-6.6816C21,47.5459,23.7275,39.9092,31,38" />
          <path fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" d="M31,38c1-0.2715,2-0.8945,2-2c0-1.1045-1-1.7285-2-2" />
          <path fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" d="M41,34c7.2725-1.9092,10-9.5454,10-14.3184C51,14.9092,51,13,51,13" />
          <path fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" d="M51,59c0,0,0-1.9092,0-6.6816C51,47.5459,48.2725,39.9092,41,38" />
          <path fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" d="M41,38c-1-0.2715-2-0.8945-2-2c0-1.1045,1-1.7285,2-2" />
          <path fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" d="M25,55c6.0742,0,11-4.9258,11-11c0,6.0742,4.9258,11,11,11" />
          <path fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" d="M55,11c0,1.1001-0.9004,2-2,2H19c-1.0996,0-2-0.8999-2-2v-1c0-1.1001,0.9004-2,2-2h34c1.0996,0,2,0.8999,2,2V11z" />
          <line x1="47" x2="25" y1="18" y2="18" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
          <path fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" d="M55,62c0,1.0996-0.9004,2-2,2H19c-1.0996,0-2-0.9004-2-2v-1c0-1.0996,0.9004-2,2-2h34c1.0996,0,2,0.9004,2,2V62z" />
          <line x1="36" x2="36" y1="39" y2="44" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
        </g>
      </svg>
    </div>
  )
}

Loading.propTypes = {
  className: PropTypes.string,
}
Loading.defaultProps = {
  className: ""
}
export default Loading
