
export default async function loadComments( postId, perPage, offset, parent ) {
  // eslint-disable-next-line max-len
  const url = `/wp-json/wp/v2/comments?per_page=${perPage}&offset=${offset}&order=asc&orderby=date&post=${postId}&parent=${parent}`
  console.log({url})
  const result = await fetch(url)
  const comments = await result.json()
  return {
    totalComments: result.headers.get( "X-Wp-Total" ),
    totalPage: result.headers.get( "X-Wp-Totalpages" ),
    comments,
  }
}
