import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import _ from "lodash";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Nav from "../components/Nav";
import Comments from "../components/Comments";

const PostFeatImg = ({ featuredImage }) => {
  if (featuredImage && typeof featuredImage?.node?.mediaDetails?.sizes !== "undefined") {
    const size = _.find(featuredImage.node.mediaDetails.sizes, { name: "featured-thumb" });
    const src = size ? size.sourceUrl : featuredImage.node.sourceUrl;
    return (
      <img
        alt={featuredImage.node.altText}
        src={src}
        className="absolute left-0 top-0 w-full h-full z-0 object-cover"
      />
    );
  }
  return <></>;
};

export default function BlogPost({ data }) {
  const post = data.wpPost;
  // const seo = post.seo

  const tags = _.get(post, "tags.nodes", false);
  const categories = _.get(post, "categories.nodes", false);
  const numberOfTags = tags !== false ? tags.length : 0;
  const numberofCategories = categories !== false ? categories.length : 0;

  function Tags() {
    if (tags && tags.length > 0) {
      const lis = _.map(tags, (tag) => {
        return (
          <li className="mr-3" key={tag.slug}>
            <a
              className="inline-block rounded-full m-1 py-1 px-3 shadow-md bg-indigo-200 hover:bg-indigo-900 text-black
                text-xs"
              href={tag.uri}
            >
              {tag.name}
            </a>
          </li>
        );
      });
      return (
        <>
          {numberOfTags > 0 && numberOfTags > 1 ? <h6 className="text-indigo-200">Tags</h6> : ""}
          {numberOfTags === 1 ? <h6 className="text-indigo-200">Tag</h6> : ""}
          {/* <h6 className="text-indigo-200 -mt-12">Tags</h6> */}
          <ul className="list-none flex flex-wrap">{lis}</ul>
        </>
      );
    }
  }

  function Categories() {
    if (categories && categories.length > 0) {
      const lis = _.map(categories, (category) => {
        return (
          <li className="mr-3" key={category.slug}>
            <a
              className="inline-block rounded-full m-1 py-1 px-3 shadow-md bg-indigo-200 hover:bg-indigo-900 text-black
                text-xs"
              href={category.uri}
            >
              {category.name}
            </a>
          </li>
        );
      });
      return (
        <>
          {numberofCategories > 0 && numberofCategories > 1 ? <h6 className="text-indigo-200">Categories</h6> : ""}
          {numberofCategories === 1 ? <h6 className="text-indigo-200">Category</h6> : ""}
          <ul className="list-none flex flex-wrap">{lis}</ul>
        </>
      );
    }
  }

  let category = "";
  if (typeof post.categories !== "undefined" && post.categories.nodes.length > 0) {
    category = (
      <a
        href={post.categories.nodes[0].uri}
        className="px-4 py-1 bg-black text-gray-200 inline-flex items-center justify-center mb-2"
      >
        {post.categories.nodes[0].name}
      </a>
    );
  }
  return (
    <Layout>
      <Seo post={post} />
      <Helmet>
        <title>{post.seo.title}</title>
      </Helmet>
      <Nav />
      <main className="mt-8">
        <div className="mb-4 md:mb-0 w-full max-w-screen-md mx-auto relative" style={{ height: "24em" }}>
          <div
            className="absolute left-0 bottom-0 w-full h-full z-10"
            style={{ backgroundImage: "linear-gradient(180deg,transparent,rgba(0,0,0,.9))" }}
          />
          <PostFeatImg featuredImage={post.featuredImage} />
          <div className="p-4 absolute bottom-0 left-0 z-20">
            {category}
            <h1 className="text-4xl font-semibold text-gray-100 leading-tight">{post.title}</h1>
          </div>
        </div>
        <article
          className="p-8 lg:p-4 max-w-screen-md mx-auto prose"
          dangerouslySetInnerHTML={{ __html: post.content }}
          style={{ backgroundColor: "#f2f4f7" }}
        />
        {/* https://tailwindcomponents.com/component/simple-welcome-card-with-alpinejs-action as a starting point */}
        <div className="bg-blue-600 text-white rounded shadow-xl py-5 px-5 mx-auto max-w-screen-md">
          <div className="flex flex-wrap -mx-3 items-center">
            <div className="w-1/4 px-3 text-center hidden md:block">
              <div className="p-5 xl:px-8 md:py-5">
                {/* <div className="w-full sm:w-1/2 md:w-1/4 px-3 text-center"> */}
                {Categories()}
              </div>
            </div>
            <div className="w-full sm:w-1/2 md:w-2/4 px-3 text-left">
              <div className="p-5 xl:px-8 md:py-5">
                <h3 className="text-2xl">Thanks for reading!</h3>
                {/* <h5 className="text-xl mb-3">First posted: </h5> */}
                <p className="text-indigo-300">{`Posted: ${post.date}`}</p>
                <p className="text-indigo-300">{`Updated: ${post.modified}`}</p>
              </div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/4 px-3 text-center">{Tags()}</div>
          </div>
        </div>
        <div
          className="p-8 lg:p-4 text-gray-700 max-w-screen-md mx-auto text-lg leading-relaxed mb-10"
          style={{ backgroundColor: "#f2f4f7" }}
        >
          <Comments postId={post.databaseId} commentCount={post.commentCount} commentStatus={post.commentStatus} />
        </div>
      </main>
    </Layout>
  );
}
export const query = graphql`
  query($postId: String) {
    wpPost(id: { eq: $postId }) {
      title
      content
      featuredImage {
        node {
          altText
          sourceUrl
          mediaDetails {
            sizes {
              file
              height
              width
              sourceUrl
              name
            }
          }
        }
      }
      seo {
        title
        metaKeywords
        opengraphType
        opengraphTitle
        opengraphDescription
        opengraphUrl
        opengraphSiteName
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphImage {
          mediaDetails {
            width
            height
          }
          sourceUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          link
        }
      }
      categories {
        nodes {
          name
          slug
          uri
        }
      }
      tags {
        nodes {
          name
          slug
          uri
        }
      }
      modified(fromNow: true)
      date(fromNow: true)
      commentStatus
      id
      databaseId
      uri
    }
    wp {
      generalSettings {
        url
      }
    }
  }
`;
