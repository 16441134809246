import React from "react"
import PropTypes from 'prop-types'
import { DateTime } from "luxon"

const Comment = ({id, gravatar, name, dateGmt, content, isChild, commentStatus, toggleReply, replyOpen}) => {
  const commentDate = DateTime.fromISO(dateGmt, { zone: 'utc' })
  const hoverDate = commentDate.toLocal().toLocaleString(DateTime.DATETIME_FULL)
  const humanDate = commentDate.toLocal().toRelative()

  return (
    <>
      <div id={`comment-${id}`} key={id} className={(isChild) ? "pl-16" : ""}>
        <article
          id={`div-comment-${id}`}
          className="w-auto bg-white shadow mx-auto mt-10 h-full p-3"
          style={{minHeight: "10rem"}}
        >
          <div className="text-xs text-gray-400 relative float-right">
            {(dateGmt !== "pending") ? (
              <time dateTime={dateGmt} title={hoverDate}>
                {humanDate}
              </time>
          ) : "preview"}

            {(commentStatus === 'open' && !isChild && dateGmt !== "pending" && (
              <button
                type="button"
                className="rounded-full bg-indigo-100 p-1 ml-1"
                onClick={toggleReply}
              >
                { (!replyOpen) ? "Reply" : "Cancel"}
              </button>
            )
            )}
          </div>
          <footer className="float-left relative">
            <div className="flex flex-col text-center items-center">
              <div className="">
                <img
                  alt=""
                  src={gravatar}
                  className="object-cover rounded-full border-indigo-500 border-2"
                  height="96"
                  width="96"
                  loading="lazy"
                />
              </div>
              <div><p className="fn">{name}</p></div>
              <div><p className="sr-only">says:</p></div>
            </div>
          </footer>
          <div
            className="clear-right block flex static prose prose-sm my-auto p-3 break-words"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </article>
      </div>
    </>
  )
}

Comment.propTypes = {
  id: PropTypes.number,
  gravatar: PropTypes.string,
  name: PropTypes.string,
  dateGmt: PropTypes.string,
  content: PropTypes.string,
  isChild: PropTypes.bool,
  commentStatus: PropTypes.string,
  toggleReply: PropTypes.func,
  replyOpen: PropTypes.bool,
}
Comment.defaultProps = {
  id: 0,
  gravatar: '',
  name: '',
  dateGmt: '',
  content: '',
  isChild: false,
  commentStatus: "open",
  toggleReply: () => {},
  replyOpen: false,
}

export default Comment
