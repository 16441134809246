import React, { useCallback, useEffect } from "react"
import _ from "lodash"
import CommentForm from "./CommentForm"
import Comment from "./Comment"

const originState = {
  gravatar: 'https://secure.gravatar.com/avatar/?&d=mm&f=y&r=g',
  name: '',
  dateGmt: "pending",
  content: '',
}

const AddReply = ({parent, replyOpen, postId, addPendingComment}) => {
  const [commentReply, setCommentReply] = React.useState(originState)

  const throttled = useCallback(_.throttle(setCommentReply, 500), [])

  const handleReplyUpdate = (key, value) => {
    const merged = _.clone(commentReply)
    _.set(merged, key, value)
    if ( typeof throttled === "function" ) {
      throttled(merged)
    }
  }

  useEffect(() => {
    if (!replyOpen) {
      setCommentReply(originState)
    }
  }, [replyOpen])


  if (replyOpen) {
    return (
      <>
        <CommentForm
          onUpdate={handleReplyUpdate}
          parent={parent}
          isChild={parent > 0}
          postId={postId}
          addPendingComment={addPendingComment}
        />
        <Comment
          className="animate-pulse"
          isChild={parent > 0}
          gravatar={commentReply.gravatar}
          name={commentReply.name}
          dateGmt={commentReply.dateGmt}
          content={commentReply.content}
        />
      </>
    )
  }
  return ''
}

export default AddReply
