/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import loadComments from './loadComments'
import Comment from "./Comment"
import AddReply from "./AddReply"

const perPage = 10

const LoadButton = ({ links, handleLoad, commentsShown }) => {
  if ( typeof links.children === "undefined" ) return (<></>)
  return (
    <div className="w-full">
      <button
        type="button"
        onClick={handleLoad}
        className="block w-1/2 px-3 py-2 m-auto rounded text-center text-sm bg-blue-500
          font-medium text-white leading-5 hover:bg-blue-600"
      >
        {`Load ${(commentsShown > 0) ? "More " : ""}Replies`}
      </button>
    </div>
  )
}

const CommentWrapper = ({ commentStatus, comment, postId, addPendingComment }) => {
  if ( comment.id <= 0 ) return (<></>)
  const [errorLoadingComments, setErrorLoadingComments] = useState(false)
  const [replyOpen, setReplyOpen] = useState(false)
  const [replies, setReplies] = useState([])
  const [totalReplies, setTotalReplies] = useState(null)
  const allowReply = comment.parent <= 0 && commentStatus

  const toggleReplyOpen = () => {
    setReplyOpen(!replyOpen)
  }

  const WrappedAddPendingComment = (data) => {
    setReplyOpen(false)
    addPendingComment(data)
  }

  const loadReplies = async () => {
    try {
      // const offset = (totalReplies === null) ? 0 : Math.ceil( totalReplies / perPage )
      const result = await loadComments(postId, perPage, replies.length, comment.id)
      setReplies(_.concat(replies, result.comments))
      setTotalReplies(result.totalComments)
    } catch (error) {
      if ( process.env.NODE_ENV === "development" ) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
      setErrorLoadingComments(error)
    }
  }

  return (
    <>
      <Comment
        id={comment.id}
        key={`comment-${comment.id}`}
        gravatar={comment.author_avatar_urls["96"]}
        name={comment.author_name}
        dateGmt={comment.date_gmt}
        content={comment.content.rendered}
        allowReply
        toggleReply={toggleReplyOpen}
        replyOpen={replyOpen}
      />
      {replies && (
        replies.map(reply => {
          return(
            <Comment
              id={reply.id}
              key={`reply-${reply.id}`}
              gravatar={reply.author_avatar_urls["96"]}
              name={reply.author_name}
              dateGmt={reply.date_gmt}
              content={reply.content.rendered}
              isChild
            />
          )})
        )}
      { !errorLoadingComments && (replies.length < totalReplies || totalReplies === null ) && (
        <LoadButton links={comment._links} commentsShown={replies} handleLoad={loadReplies} />
      )}
      {errorLoadingComments && (errorLoadingComments)}
      {allowReply && (
      <AddReply
        key={`reply-${comment.id}`}
        parent={comment.id}
        postId={postId}
        replyOpen={replyOpen}
        toggleReply={toggleReplyOpen}
        addPendingComment={WrappedAddPendingComment}
      />
      )}
    </>
  )
}

CommentWrapper.propTypes = {
  commentStatus: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  comment: PropTypes.object.isRequired,
  postId: PropTypes.number.isRequired,
  addPendingComment: PropTypes.func.isRequired,
}

export default CommentWrapper
