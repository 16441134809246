import React, { useMemo, useState } from "react"
import PropTypes from 'prop-types'
import _ from 'lodash'
import loadComments from './loadComments'
import CommentWrapper from "./CommentWrapper"
import AddReply from "./AddReply"

const perPage = 10

const CommentList = ({ postId, addPendingComment, comments }) => {
  if ( comments.length <= 0 ) return (<></>)
  return _.map(comments, (comment, index) => {
    return (
      <CommentWrapper
        commentStatus
        comment={comment}
        key={comment.id || index}
        postId={postId}
        addPendingComment={addPendingComment}
      />
    )
  })
}

const LoadButton = ({ totalComments, commentsShown, handleLoad }) => {
  if ( commentsShown >= totalComments ) return (<></>)
  return (
    <div className="w-full">
      <button
        type="button"
        onClick={handleLoad}
        className="block w-1/2 px-3 py-2 m-auto rounded text-center text-sm bg-blue-500
          font-medium text-white leading-5 hover:bg-blue-600"
      >
        {`Load ${(commentsShown > 0) ? "More " : ""}Comments`}
      </button>
    </div>
  )
}

const Comments = ({ postId, commentStatus }) => {
  const [errorLoadingComments, setErrorLoadingComments] = useState(false)
  const [loadState, setLoadState] = useState(0) // possible states: 0 = no; 1 = waiting for ajax; 2 = ajax complete
  const [comments, setComments] = useState([])
  const [totalParentComments, setTotalParentComments] = useState(null)
  const [showReplyForm, setShowReplyForm] = useState(false)

  const toggleShowReplyForm = () => {
    setShowReplyForm(!showReplyForm)
  }

  const addPendingComment = (pendingComment) => {
    setComments([...comments, pendingComment])
    setShowReplyForm(false)
  }

  const handleLoad = async () => {
    try {
      // const offset = (totalParentComments === null) ? 0 : Math.floor( totalParentComments / perPage )
      const result = await loadComments(postId, perPage, comments.length, 0)
      setComments(_.concat(comments, result.comments))
      setTotalParentComments(result.totalComments)
      setLoadState(2)
    } catch (error) {
      if ( process.env.NODE_ENV === "development" ) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
      setErrorLoadingComments(error)
    }
  }

  if ( errorLoadingComments ) {
    return (
      <div>
        Error:
        {errorLoadingComments.message}
      </div>
    )
  }

  return (
    <>
      {(loadState >= 2 && comments.length > 0) &&
          (<CommentList postId={postId} addPendingComment={addPendingComment} comments={comments} />)}
      <LoadButton
        totalComments={totalParentComments}
        commentsShown={comments.length}
        handleLoad={handleLoad}
      />
      {(commentStatus === 'open' && !showReplyForm && (
      <div className="w-full">
        <button
          type="button"
          onClick={toggleShowReplyForm}
          className="block w-1/2 px-4 py-2 mt-3 mx-auto rounded text-center text-sm bg-blue-500
                font-medium text-white leading-5 hover:bg-blue-600"
        >
          Add your comment
        </button>
      </div>
        ))}
      {showReplyForm && (
        <AddReply
          parent={0}
          postId={postId}
          replyOpen={showReplyForm}
          toggleReply={toggleShowReplyForm}
          addPendingComment={addPendingComment}
        />
        )}
    </>
    )
}

Comments.propTypes = {
  postId: PropTypes.number.isRequired,
  commentStatus: PropTypes.string.isRequired,
}

export default Comments
