import React from "react"
import PropTypes from 'prop-types'
import { getGravatarAvatar } from "gravatar-utils"
import { validate as validateEmail } from "email-validator"
// eslint-disable-next-line camelcase
import { nl2br, strip_tags } from "locutus/php/strings"
import { truncate, some, get } from "lodash"
import HCaptchaField from "./HCaptchaField"
import Loading from "./Loading"

const stripTags = (content) => {
  const safeish = content.replace("<", "&lt;").replace(">", "&gt;")
  return nl2br(safeish)
}

const Overlay = ({children, isChild}) => {
  if (some(children)) { // stay blank unless there are any children to render.
    const width = (isChild) ? "calc(100% - 4rem)" : "100%"
    return(
      <div
        className="absolute top-0 right-0 h-full bg-black bg-opacity-75"
        style={{width}}
      >
        {children}
      </div>
    )
  }
  return <></>
}

Overlay.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  isChild: PropTypes.bool.isRequired,
}

Overlay.defaultProps = {
  children: [],
}

const CommentForm = ({ onUpdate, isChild, parent, postId, addPendingComment }) => {

  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [comment, setComment] = React.useState("")
  const [showCaptcha, setShowCaptcha] = React.useState(false)
  const [showLoading, setShowLoading] = React.useState(false)
  const [showError, setShowError] = React.useState(false)
  const [clearOverlayTimer, setClearOverlayTimer] = React.useState(false)

  const handleChange = (event) => {
    if ( event.target ) {
      const { value, name: elementName } = event.target
      switch (elementName) {
        case "email":
          setEmail(value)
          if ( validateEmail(value) ) {
            onUpdate('gravatar', `${getGravatarAvatar(value)}&d=mm` )
          }
          break;
        case "name":
          onUpdate('name', truncate(value, 20))
          setName(value)
          break;
        case "comment":
          onUpdate('content', stripTags(value))
          setComment(value)
          break;

        default:
          break;
      }
    }
  }

  const clearError = () => {
    clearTimeout(clearOverlayTimer)
    setClearOverlayTimer(false)
    setShowError(false)
  }

  const submitComment = () => {
    setShowCaptcha(true)
  }
  const captchaSubmit = async (token) => {
    setShowCaptcha(false)
    setShowLoading(true)
    const data = {
      name,
      email,
      comment,
      token,
      parentId: parent,
      postId,
    }

    const result = await fetch('/wp-json/doug/v1/comment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    })
    const json = await result.json()

    if ( result.status === 200 ) {
      setName()
      setEmail()
      setComment()
      addPendingComment(json)
    } else {
      // error handling
      setShowLoading(false)
      if ( ! clearOverlayTimer ) {
        setClearOverlayTimer(setTimeout(() => {
            clearError()
        }, 5000))
      }
      const message = get(json, 'message', 'Error saving comment, maybe reach out on twitter?')
      setShowError(strip_tags(message))
    }

  }

  const submitDisabled = React.useMemo(() => {
    if ( process.env.NODE_ENV === "development" ) return false
    return !(
      typeof name === "string" &&
      name.length > 2 &&
      typeof email === "string" &&
      validateEmail(email) &&
      typeof comment === "string" &&
      comment.length > 5
      )
  }, [name, comment, email])

  return (
    <>
      <div className={`${(isChild) ? "pl-16" : ""} relative`}>
        <Overlay isChild={isChild}>
          {showCaptcha && (
          <div className="flex items-center justify-center">
            <HCaptchaField className="mt-16" onSubmit={captchaSubmit} />
          </div>
          )}
          {showLoading && (
            <div className="grid h-full">
              <Loading className="animate-bounce m-auto" />
            </div>
          )}
          {showError && (
          <div className="grid h-full">
            <div className="flex flex-row bg-white shadow-sm rounded m-4">
              <div className="text-black text-xl font-bold m-auto text-center">
                <svg
                  viewBox="0 0 72 72"
                  xmlns="http://www.w3.org/2000/svg"
                  className="animate-spin h-12 w-12 stroke-current text-red-500 fill-current float-left"
                >
                  {/* stroke-current text-black fill-current */}
                  <g id="line">
                    <circle
                      cx="36"
                      cy="36"
                      r="30"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <path
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m55.66 51.44a24.99 24.99 0 0 0-35.06-35.13z"
                    />
                    <path
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m16.99 19.77a24.99 24.99 0 0 0 35.2 35.27z"
                    />
                  </g>
                </svg>
                <div className="m-auto">{showError}</div>
                <button
                  type="button"
                  className="rounded-full h-12 w-12 flex items-center shadow hover:bg-indigo-300
                    justify-center bg-indigo-100 absolute bottom-6 right-6"
                  onClick={clearError}
                >
                  ok
                </button>
              </div>

            </div>
          </div>
          )}
        </Overlay>
        <form
          id="commentReply"
          className="w-auto bg-white shadow mx-auto mt-10 h-full p-3"
          style={{minHeight: "12.5rem"}}
        >
          <div className="w-1/4 float-left">
            <label htmlFor="name">
              <span className="sr-only">Full Name</span>
              <input
                type="name"
                name="name"
                id="commentName"
                onChange={handleChange}
                value={name}
                placeholder="Full Name"
                className={`w-full mt-2 py-3 px-3 rounded-lg bg-white border
                  text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none`}
              />
            </label>
            <label htmlFor="email">
              <span className="sr-only">Email</span>
              <input
                type="email"
                name="email"
                id="commentEmail"
                onChange={handleChange}
                value={email}
                placeholder="email@domain.com"
                className={`w-full mt-2 py-3 px-3 rounded-lg bg-white border
                  text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none`}
              />
            </label>
          </div>
          <div className="w-9/12 h-full float-left pl-2">
            <label htmlFor="comment">
              <span className="sr-only">Comment</span>
              <textarea
                name="comment"
                id="commentContent"
                onChange={handleChange}
                value={comment}
                rows="3"
                spellCheck="true"
                placeholder="Enter your comment"
                className={`w-full h-full mt-2 py-3 px-3 rounded-lg bg-white border
                  text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none`}
              />
            </label>
          </div>
          <div className="w-full">
            <button
              type="button"
              onClick={submitComment}
              disabled={submitDisabled}
              className="rounded bg-blue-600 text-white p-2 ml-2 float-right disabled:cursor-not-allowed
                disabled:opacity-50"
            >
              Submit
            </button>
          </div>
        </form>

      </div>
    </>
  )
}

CommentForm.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  isChild: PropTypes.bool.isRequired,
  parent: PropTypes.number.isRequired,
  postId: PropTypes.number.isRequired,
  addPendingComment: PropTypes.func.isRequired,
}

export default CommentForm
